<template>
  <v-card class="my-5" flat>
    <div class="d-flex align-center flex-wrap pa-2">
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        clearable
        style="max-width: 250px"
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
      />
      <v-btn
        class="gradient-primary ma-2"
        dark
        depressed
        @click="openDialog = true"
      >
        {{
          this.permissionType == "TEACHER"
            ? roleAdmin
              ? $t("permission.add_teacher_permission")
              : $t("permission.added_my_permission")
            : roleAdmin
            ? $t("permission.add_staff_permission")
            : $t("permission.added_my_permission")
        }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="arrayDelete.length !== 0"
        class="gradient-error ma-2"
        dark
        depressed
        @click="confirmDelete = true"
      >
        {{ $t("permission.delete_teacher") }}
        <v-icon class="ml-2">mdi-delete</v-icon>
      </v-btn>
    </div>
    <v-divider class="my-2" />
    <Table
      :table="table"
      :modelTable="arrayDelete"
      withAction
      @setSingleDelete="
        item => {
          singleDelete = item;
          confirmDelete = true;
        }
      "
      @setMultipleDelete="item => (arrayDelete = item)"
      @postDelete="saveDelete"
    />
    <Pagination
      :length="table.dataTable.last_page"
      :total="table.dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
      class="ma-3"
    />

    <v-dialog
      v-model="openDialog"
      transition="scale-transition"
      persistent
      max-width="750px"
    >
      <v-card flat>
        <v-card-title>
          {{
            isAdmin
              ? $t("permission.add_teacher_permission")
              : $t("permission.added_my_permission")
          }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loadingSave" @click="closeDialog"
              >mdi-close</v-icon
            >
          </div>
        </v-card-title>
        <v-card-text>
          <FormPermission
            :bodyRequest="bodyRequest"
            :listPermission="listMaster"
            :loading="loadingMaster"
            :checkValidate="validateForm"
            :reset="resetForm"
            :dataEmployee="dataEmployee"
            :loadingEmployee="loadingEmployee"
            :isAdmin="isAdmin"
            :permissionType="permissionType"
            @selectAll="data => (bodyRequest.person = data)"
          />
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <div style="float: right">
              <v-btn
                :disabled="loadingSave"
                :dark="!loadingSave"
                outlined
                color="primary"
                class="mr-2"
                @click="closeDialog"
                >{{ $t("app.cancel") }}</v-btn
              >
              <v-btn
                :loading="loadingSave"
                color="gradient-primary"
                depressed
                dark
                @click="save()"
              >
                {{ $t("app.save") }}
              </v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirm
      :close="() => (this.confirmDelete = false)"
      :isOpen="confirmDelete"
      :save="saveDelete"
      :loading="loadingDelete"
    />
  </v-card>
</template>

<script>
import {
  getListPermission,
  getListMaster,
  addPermission,
  deletePermission
} from "@/api/admin/absent/permission";
import { getPermissionEmployee } from "@/api/admin/absent/permission";
import { uploadFile } from "@/api/admin/admin";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    init: Boolean,
    permissionType: String
  },
  components: {
    Table: () => import("./components/Table"),
    Pagination: () => import("@/components/Pagination"),
    FormPermission: () => import("./components/FormPermission"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  computed: {
    search: {
      set(value) {
        this.table.search = value;
        this.searchHandler();
      },
      get() {
        return this.table.search;
      }
    }
  },
  watch: {
    openDialog(value) {
      if (value) {
        this.resetForm = false;
        this.masterPermission();
        if (this.isAdmin) this.getListEmployee();
      }
    },
    init: {
      immediate: true,
      handler() {
        if (this.init) this.getPermission();
      }
    }
  },
  created() {
    const isAdmin = this.$store.getters.g_role_type === "ADMIN";
    this.isAdmin = isAdmin;
    if (isAdmin) this.bodyRequest.person = [];
  },
  data() {
    return {
      confirmDelete: false,
      loadingDelete: false,
      arrayDelete: [],
      singleDelete: {},
      isAdmin: false,
      validateForm: false,
      resetForm: false,
      loadingMaster: false,
      loadingSave: false,
      listMaster: [],
      bodyRequest: {
        leave: 0,
        type: "employee",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: "",
        employment_type: this.permissionType
      },
      openDialog: false,
      table: {
        dataTable: { data: [] },
        loading: false,
        search: "",
        page: 1,
        limit: 10
      },
      loadingEmployee: false,
      dataEmployee: [],
      roleAdmin: this.$store.getters.g_role_type === "ADMIN"
    };
  },
  methods: {
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.page = 1;
        this.getPermission();
      }, doneTypingInterval);
    },
    async saveDelete() {
      this.loadingDelete = true;
      const body = this.arrayDelete.length
        ? this.arrayDelete
        : [this.singleDelete];
      try {
        const response = await deletePermission({ data: body });
        if (response.data.code) {
          this.getPermission();
          this.snackBar(
            false,
            this.$i18n.t("permission.msg.delete_permission")
          );
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        console.err("saveDelete()\n", error);
      } finally {
        this.loadingDelete = false;
        this.confirmDelete = false;
        this.arrayDelete = [];
      }
    },
    async uploadImage() {
      let result = "";
      let bodyImage = new FormData();
      bodyImage.set("path", "permission/teacher");
      bodyImage.set("module", "PERMISSION");
      bodyImage.append("file", this.bodyRequest.temp_file);
      if (this.bodyRequest.temp_file) {
        try {
          const response = await uploadFile(bodyImage);
          if (response.data.code) {
            result = response.data.data.path;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      }
      return result;
    },
    async getListEmployee() {
      this.loadingEmployee = true;
      try {
        const bodyRequest = {
          // filter: {
          //   "employees.status": [],
          //   "employees.type": [this.permissionType]
          // }
          type: this.permissionType
        };
        const response = await getPermissionEmployee(bodyRequest);
        if (response.data.code) {
          this.dataEmployee = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingEmployee = false;
    },
    closeDialog() {
      this.openDialog = false;
      this.reset();
      this.getPermission();
    },
    reset() {
      this.dataEmployee = [];
      this.bodyRequest = {
        leave: 0,
        type: "employee",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: "",
        employment_type: this.permissionType
      };
      if (this.isAdmin) this.bodyRequest = { ...this.bodyRequest, person: [] };
      this.resetForm = true;
    },
    async save() {
      const person = this.isAdmin ? this.bodyRequest.person.length > 0 : true;
      this.validateForm = false;
      if (
        this.bodyRequest.leave &&
        this.bodyRequest.date_in &&
        this.bodyRequest.desc &&
        this.bodyRequest.date_out &&
        person
      ) {
        this.loadingSave = true;
        try {
          if (!this.bodyRequest.file) {
            this.bodyRequest.file = await this.uploadImage();
          }
          const response = await addPermission(this.bodyRequest);
          if (response.data.code) {
            this.snackBar(false, response.data.message);
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.map(r => {
                this.$socket.emit("serverNotif", {
                  recipient: r.recipient,
                  data: r
                });
              });
            }
            this.closeDialog();
          } else {
            this.snackBar(true, response.data.message);
          }
        } catch (error) {
          this.snackBar(true, error);
        }
        this.loadingSave = false;
      } else {
        this.validateForm = true;
      }
    },
    async masterPermission() {
      this.loadingMaster = true;
      try {
        const response = await getListMaster({
          prefix: "employee"
        });
        if (response.data.code) {
          this.listMaster = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.loadingMaster = false;
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getPermission();
    },
    async getPermission() {
      this.table.loading = true;
      try {
        const response = await getListPermission({
          type: "employee",
          search: this.table.search,
          page: this.table.page,
          limit: this.table.limit,
          employment_type: this.permissionType
        });
        if (response.data.code) {
          this.table.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.table.loading = false;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    }
  }
};
</script>
